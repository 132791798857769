import React from 'react'

import { Link } from 'gatsby'
import { ErrorBox, CustomButton, Center } from '../styles/general.styled'

const Error404 = () => {
    return(
        <ErrorBox>
            <Center>
                <h1>Sorry, we can’t find the page you were looking for.</h1>
                <Link to = "/">
                    <CustomButton>
                        Back to home.
                    </CustomButton>
                </Link>
            </Center>
        </ErrorBox>
    )
}

export default Error404