import styled from 'styled-components'

export const Flex = styled.div<{ 
    direction?: string, 
    alignItems?: string,
    flex?: string,
}>`
display: flex;
flex: ${props => props.flex ? props.flex : 'none'};
flex-direction: ${props => props.direction ? props.direction : 'none'};
align-items: ${props => props.alignItems ? props.alignItems : 'none'};
`

export const ErrorBox = styled.div`
    position:absolute;
    margin: 0;
    text-align: center;
    height: 100%;
    width: 100%;
`

export const Center = styled.div`
    position: absolute;
    margin: 0;
    top:50%;
    left:50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
`

export const CustomButton = styled.button<{
    color?: string,
}>`
    background-color: ${props => props.color ? props.color : '#007BFF' };
    border-color: ${props => props.color ? props.color : '#007bff' };
    color: #fff;
    padding: 0.375rem 0.75rem;
    cursor: pointer;
    border-radius: 0.25rem;
    font-size: 1.25rem;
    
    :hover {
        background-color: #0069d9;
        border-color: #0062cc;
        text-decoration: none;
    }
}
`

